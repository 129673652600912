import "./styles.scss";

import React from "react";
import { Link } from "gatsby";
import ReactPlayer from "react-player";

const TraderWidget = () => {
	return (
		<div className="trader-widget">
			<div className="trader-widget__avatar">
				<ReactPlayer
					url="https://when.zenx.pl/wp-content/themes/adream/assets/video/persons/patrycja-idzinska_new.mp4"
					width="100%"
					height="100%"
					playing={true}
					muted={true}
					playsinline={true}
					loop={true}
				/>
			</div>
			<p className="trader-widget__name">Patrycja Idzińska</p>
			<ul className="trader-widget__list">
				<li>New Business Director</li>
				<li>
					<a href="tel:+48512998008">+48 512 998 008</a>
				</li>
				<li>
					<a href="mailto:p.idzinska@when.pl">p.idzinska@when.pl</a>
				</li>
				<Link
					to="/kontakt/"
					className="trader-widget__button btn btn-box"
				>
					skontaktuj się
				</Link>
			</ul>
		</div>
	);
};

export default TraderWidget;

import React from "react";
import { Link } from "gatsby";
import Slider from "react-slick";

import { Layout, CaseStudyHeader, ContactFormLight } from "../components";
import locales from "../constants";
import TraderWidget from "../components/TraderWidget";
// import ReactPlayer from "react-player";

const pageCaseStudy = ({ pageContext }) => {
	const { title, caseContent, seo } = pageContext.data;

	const slider = {
		dots: false,
		arrows: true,
		infinite: true,
		draggable: true,
		slidesToShow: 2,
		autoplay: false,
		slidesToScroll: 1,
		responsive: [
			{
				breakpoint: 575,
				settings: {
					slidesToShow: 1,
				},
			},
		],
	};

	return (
		<Layout
			seo={{
				...seo,
				href: "pl",
				lang: "pl",
			}}
		>
			<CaseStudyHeader title={title} />
			<div className="trader-wrapper--case-studies">
				<TraderWidget />
			</div>
			<section className="case-single-content">
				{console.log(caseContent)}
				{caseContent.map((item) => (
					<div>
						{item.content ? (
							<div
								className="case-container"
								dangerouslySetInnerHTML={{
									__html: item.content,
								}}
							/>
						) : item.full_img_url ? (
							// <img className="image image--big" alt="" src={item.full_img_url.source_url}/>
							<div
								className="image image--big"
								style={{
									backgroundImage: `url(${item.full_img_url.source_url})`,
								}}
							/>
						) : item.text_right ? (
							<div className="case-container">
								<div className="row split-content">
									<div className="col-md-5">
										<img
											className="image image--left img-fluid"
											alt=""
											src={item.img_left_url.source_url}
										/>
									</div>
									<div className="col-md-6 offset-md-1">
										<div
											dangerouslySetInnerHTML={{
												__html: item.text_right,
											}}
										/>
									</div>
								</div>
							</div>
						) : item.text_left ? (
							<div className="case-container">
								<div className="row split-content">
									<div className="col-md-6">
										<div
											dangerouslySetInnerHTML={{
												__html: item.text_left,
											}}
										/>
									</div>
									<div className="col-md-5 offset-md-1">
										<img
											className="image image--right img-fluid"
											alt=""
											src={item.img_right_url.source_url}
										/>
									</div>
								</div>
							</div>
						) : item.other_projects_header ? (
							<div className="other-posts-section">
								<h2 className="other-posts-section__title">
									{item.other_projects_header}
								</h2>
								<div className="row other-posts-row">
									<div className="col-lg-6">
										<Link to={item.left_post.link}>
											<div className="other-post-wrapper other-post-wrapper--left">
												<h3 className="other-post-wrapper__title">
													{item.left_post.title}
												</h3>
												<img
													src={
														item.left_post.image
															.source_url
													}
													alt=""
													className="other-post-wrapper__image img-fluid"
												/>
											</div>
										</Link>
									</div>
									<div className="col-lg-6">
										<Link to={item.right_post.link}>
											<div className="other-post-wrapper other-post-wrapper--right">
												<h3 className="other-post-wrapper__title">
													{item.right_post.title}
												</h3>
												<img
													src={
														item.right_post.image
															.source_url
													}
													alt=""
													className="other-post-wrapper__image img-fluid"
												/>
											</div>
										</Link>
									</div>
								</div>
							</div>
						) : item.gallery_content ? (
							<div className="other-posts-gallery">
								<div className="case-container">
									<Slider {...slider}>
										{item.gallery_content?.map(
											(node, i) => (
												<div key={i}>
													<div className="other-posts-gallery__item">
														<img
															className="img-fluid"
															src={
																node?.localFile
																	?.publicURL
															}
															alt=""
														/>
													</div>
												</div>
											)
										)}
									</Slider>
								</div>
							</div>
						) : (
							""
						)}
					</div>
				))}
			</section>

			<section className="single-post-section-contact case-study">
				<div className="form-wrapper">
					<h2 className="single-post-section-contact__title mb-40">
						Skontaktuj się z nami! <br />
						Wypełnij <span>formularz</span>
					</h2>
					<ContactFormLight
						locales={locales["pl"]}
						instagramSelect={false}
						thankYouTarget="dziekujemy-za-kontakt-case-study"
					/>
				</div>
				<img
					className="img-fluid single-offer-section-contact__hand"
					src={require("../assets/images/home-contact-hand-right.png")}
					alt=""
				/>
			</section>
		</Layout>
	);
};

export default pageCaseStudy;
